import { Container, Skeleton } from "@mui/material";
import React from "react";
import { useAuth } from "../hooks/useAuth";
import "../styles/home.scss";
import ClickableCard from "../components/ClickableCard";
import ThompsonSpencerSingleLineLogo from "../assets/images/ThompsonSpencerSingleLineLogo";
const modules = [
  { id: 1, name: "Quote Builder", link: "https://sales.thompsonspencer.com" },
  {
    id: 2,
    name: "Upload Receipts & Expenses",
    link: "https://pay.thompsonspencer.com",
  },
  { id: 3, name: "Media", link: "https://hub.thompsonspencer.com/v2/clients" },

  {
    id: 4,
    name: "Magnesium Production",
    link: "https://production.thompsonspencer.com",
  },
  {
    id: 5,
    name: "The Social Club",
    link: "https://hub.thompsonspencer.com/project-status?department=The Social Club",
  },
  {
    id: 6,
    name: "Socialites",
    link: "https://hub.thompsonspencer.com/project-status?department=Socialites",
  },
  {
    id: 7,
    name: "Flying Tiger",
    link: "https://hub.thompsonspencer.com/project-status?department=Flying Tiger",
  },
  {
    id: 8,
    name: "People of Influence",
    link: "https://hub.thompsonspencer.com/project-status?department=People of Influence",
  },
  {
    id: 9,
    name: "Reconciliation Centre",
    link: "https://hub.thompsonspencer.com/Finance/ReconCentre",
  },
  {
    id: 10,
    name: "Wip",
    link: "https://docs.google.com/spreadsheets/d/1FqxPcfXqjw09u_XRi_AWVc-KvVm4mmQN1KBV05O_Fq0/edit?gid=1688124937#gid=1688124937",
  },
  {
    id: 11,
    name: "Clients & Supplier Database",
    link: "https://sales.thompsonspencer.com/company",
  },
  {
    id: 12,
    name: "Time Tracker",
    link: "https://timetracker.thompsonspencer.com",
  },
  {
    id: 13,
    name: "Sales Tracker",
    link: "https://sales.thompsonspencer.com/admin/dashboard",
  },
];
const Home = () => {
  const { user } = useAuth();

  const handleGoToModule = (e, system) => {
    e.preventDefault();
    if (system.link) window.open(system.link, "_blank");
  };
  return (
    <div className="home-dashboard-root">
      <Container maxWidth="lg">
        <div className="home-dashboard-main-container">
          {user.firstName ? (
            <div className="home-dashboard-heading">
              Kia Ora, {user.firstName ? user.firstName : ""}
            </div>
          ) : (
            <Skeleton variant="rounded" width={400} height={60} />
          )}

          <div className="home-dashboard-systems-wrapper">
            {modules.map((module) => (
              <ClickableCard
                key={module.id}
                title={module.name}
                handleOnClick={handleGoToModule}
                record={module}
              />
            ))}
          </div>
          
        </div>
      </Container>
    </div>
  );
};

export default Home;
