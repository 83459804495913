import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import {
  Grid,
  Skeleton,
  Typography,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import {
  Search as SearchIcon
} from "@mui/icons-material";
import { useAuth } from "../hooks/useAuth";
import UserTable from '../components/Users/UserTable';
import UserFilter from '../components/Users/UserFilter';
import "../styles/users.scss";
import AccessDenied from './AccessDenied';
import { useNavigate } from "react-router-dom";

const Users = () => {
  const { user, firebaseUser } = useAuth();
  const navigate = useNavigate();
  const emptyUserFilter = {
    department: [],
    status: []
  };
  const bottomElementRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keywordlookUp, setKeywordlookUp] = useState("");
  const [userFilters, setUserFilters] = useState(emptyUserFilter);
  const [departments, setDepartments] = useState([]);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);

  const updateUserInStore = (user) => {
    setUsers(users.map(u => {
      if (u.id == user.id) {
        return user;
      } else {
        return u;
      }
    }))
  }

  const handleSearchUsers = () => {
    setHasMoreData(true);
    searchUsers(true);
  };

  const searchUsers = (shouldBypass = false) => {
    if (!shouldBypass) {
      if (!hasMoreData || isLoading || isLoadingMore) {
        return; // No more data to fetch
      }
    }
    setIsLoading(true)
    axios.post(`Users/SearchUsers`, {
      department: userFilters.department.length > 0 ? userFilters.department.map(d => d.id) : [],
      status: userFilters.status,
    }, {
      params: {
        keyword: keywordlookUp,
        page: currentPage
      }
    }).then(({ data }) => {
      if (data.length === 0) {
        setHasMoreData(false);
        if (currentPage == 0) {
          setUsers([]);
        }
      } else {
        if (currentPage === 0) {
          setUsers(data);
        } else {
          setUsers((prevContacts) => [...prevContacts, ...data]);
        }
        setCurrentPage(currentPage + 1);
      }
    }).finally(() => setIsLoading(false));
  }

  const getDepartments = () => {
    axios.get(`Departments/GetDepartments`)
      .then(res => {
        setDepartments(res.data);
      }).catch(e => console.log(e))
  }

  const handleApplyFilter = () => {
    setHasMoreData(true);
    setCurrentPage(0);
    setApplyFilterClicked(true);
  };

  useEffect(() => {
    getDepartments();
  }, [])

  useEffect(() => {
    if (keywordlookUp) {
      setCurrentPage(0);
    }
  }, [keywordlookUp]);

  useEffect(() => {
    if (applyFilterClicked) {
      setApplyFilterClicked(false);
      searchUsers(true);
    }
  }, [applyFilterClicked])

  useEffect(() => {
    if (!isLoading && hasMoreData && !isLoadingMore) {
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            setIsLoadingMore(true); // prevent making multiple calls
            searchUsers();
            setIsLoadingMore(false);
          }
        },
        { threshold: [1] }
      ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

      if (bottomElementRef.current) {
        console.log("Observing:", bottomElementRef.current);
        observer.observe(bottomElementRef.current);
      }

      return () => {
        if (bottomElementRef.current) {
          observer.unobserve(bottomElementRef.current);
        }
      };
    }
  }, [isLoading, hasMoreData, isLoadingMore]);

  useEffect(() => {
    if(user.role !== "Super User"){
      navigate("/access-denied");
    }
  }, [user]);

  return user.role == "Super User" ?  (
    <div className='users-container'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant={"h4"} className='home-dashboard-heading'>Users</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            label="Search Users (Press Enter to Search)"
            onChange={(e) => setKeywordlookUp(e.target.value)}
            value={keywordlookUp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchUsers();
              }
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="filters-container">
            <UserFilter
              title={"Department"}
              filterType={"department"}
              options={departments}
              spanClass="department-filter-button-container"
              selectedOptions={userFilters.department}
              setSelectedOptions={value => {
                var newFilter = {
                  ...userFilters,
                  department: value
                }
                setUserFilters(newFilter);
                handleApplyFilter();
              }}
              allSelectedByDefault={true}
            />
            <UserFilter
              title={"Status"}
              filterType={"status"}
              options={["Active", "Inactive"]}
              spanClass="status-filter-button-container"
              selectedOptions={userFilters.status}
              setSelectedOptions={value => {
                var newFilter = {
                  ...userFilters,
                  status: value
                }
                setUserFilters(newFilter);
                handleApplyFilter();

              }}
              allSelectedByDefault={true}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <UserTable
            users={users}
            updateUserInStore={updateUserInStore}
            departments={departments}
          />
        </Grid>
        {isLoading && (
          <Grid item xs={12} md={12} lg={12}>
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
          </Grid>
        )}
      </Grid>
      <div ref={bottomElementRef}></div>
    </div>
  ) : <div></div>
}

export default Users